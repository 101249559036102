import {Box, Group, Text} from '@mantine/core';

import {GovernanceVoteOptionType} from '@rockawayxlabs/observatory-database';

import {ProgressWithSharedTooltip} from '~/components/ProgressWithSharedTooltip/ProgressWithSharedTooltip';
import type {ProgressWithSharedTooltipProps} from '~/components/ProgressWithSharedTooltip/ProgressWithSharedTooltip';
import {NumberFormat} from '~/utils/NumberFormat';
import {formatUnderscoreCase} from '~/utils/format';

const voteTypeColor: Record<GovernanceVoteOptionType, string> = {
  [GovernanceVoteOptionType.YES]: 'var(--observatory-color-score-high)',
  [GovernanceVoteOptionType.NO]: 'var(--observatory-color-score-critical)',
  [GovernanceVoteOptionType.NO_WITH_VETO]: 'red.9',
  [GovernanceVoteOptionType.ABSTAIN]: 'gray.5',
  [GovernanceVoteOptionType.UNSPECIFIED]: 'transparent',
};

interface TallyResultProps extends Omit<ProgressWithSharedTooltipProps, 'sections'> {
  votingResults: {
    label: GovernanceVoteOptionType;
    ratio: Percentage;
    votingPower: Amount;
  }[];
}

export function TallyResult({votingResults, ...others}: TallyResultProps) {
  if (votingResults.length <= 0) {
    return null;
  }

  return (
    <ProgressWithSharedTooltip
      sections={votingResults.map(({ratio, label, votingPower}, _, allResults) => {
        const color = voteTypeColor[label];

        return {
          id: label,
          value: ratio * 100,
          tooltip: active => (
            <Group key={label} gap={5}>
              <Box
                bg={color}
                w={10}
                h={10}
                style={{
                  flex: 'none',
                  borderRadius: 9999,
                }}
              />
              <Text size="sm" fw={active ? 700 : 400}>{`${formatUnderscoreCase(
                label
              )}: ${NumberFormat.fractionalPercentage(ratio)} (${NumberFormat.decimal(votingPower)})`}</Text>
            </Group>
          ),
          label: allResults.some(differentResult => differentResult.ratio > ratio)
            ? ''
            : `${NumberFormat.fractionalPercentage(ratio)}`,
          color,
        };
      })}
      {...others}
    />
  );
}
