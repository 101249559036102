import {Progress, Stack, Tooltip} from '@mantine/core';
import type {MantineColor, MantineStyleProps, ProgressRootProps} from '@mantine/core';
import {type ReactNode, useMemo} from 'react';

interface ProgressSection {
  id: string | number;
  value: number;
  color: MantineColor;
  label?: string;
  fz?: MantineStyleProps['fz'];
  tooltip: (active: boolean) => ReactNode;
}

export interface ProgressWithSharedTooltipProps extends ProgressRootProps {
  sections: ProgressSection[];
}

export function ProgressWithSharedTooltip({sections: sectionsProp, ...others}: ProgressWithSharedTooltipProps) {
  const sections = useMemo(() => {
    const tooltips = sectionsProp.map(({id, tooltip}) => [id, tooltip] as const);
    return sectionsProp.map(({tooltip, ...section}) => {
      return {
        ...section,
        tooltip: (
          <Stack key={section.id} gap={5}>
            {tooltips.map(([sectionId, tooltip]) => (sectionId === section.id ? tooltip(true) : tooltip(false)))}
          </Stack>
        ),
      };
    });
  }, [sectionsProp]);

  return (
    <Progress.Root radius={0} size="xl" {...others}>
      {sections.map(section => (
        <Tooltip key={section.id} label={section.tooltip}>
          <Progress.Section value={section.value} color={section.color}>
            {section.label && <Progress.Label fz={section.fz ?? 'xs'}>{section.label}</Progress.Label>}
          </Progress.Section>
        </Tooltip>
      ))}
    </Progress.Root>
  );
}
