import {Badge} from '@mantine/core';
import type {BadgeProps} from '@mantine/core';

import {GovernanceProposalStatus as ProposalStatus} from '@rockawayxlabs/observatory-database';

import {formatUnderscoreCase} from '~/utils/format';

const statusColors: Record<ProposalStatus, string> = {
  [ProposalStatus.PASSED]: 'green',
  [ProposalStatus.REJECTED]: 'red',
  [ProposalStatus.FAILED]: 'orange',
  [ProposalStatus.UNSPECIFIED]: 'gray',
  [ProposalStatus.VOTING_PERIOD]: 'cyan',
  [ProposalStatus.DEPOSIT_PERIOD]: 'teal',
};

interface GovernanceProposalStatusBadgeProps extends BadgeProps {
  status: ProposalStatus;
}

export function GovernanceProposalStatusBadge({status, ...others}: GovernanceProposalStatusBadgeProps) {
  const title = formatUnderscoreCase(status);

  return (
    <Badge variant="light" color={statusColors[status]} title={title} {...others}>
      {title}
    </Badge>
  );
}
